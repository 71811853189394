import store from '@/store';

export function ensureRegion(region?: string): string {
    if (!region) {
        return store.state.regionCode;
    }

    return region;
}

export function uuidv4() {
    let d = new Date().getTime();
    let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
}

export function renderUiString(text: string): string {
    text = '<span>' + text.replace(/#([a-z])/g, '</span><span class="format-$1">') + '</span>';
    text = text.replace(/\\n/g, '<br/>');
    text = text.replace('\n', '<br/>');
    return text;
}
